import React from "react";
import Main from "./Main";
import "./App.scss";
import net from "./hooks/classes/network";

function App() {
  if ("serviceWorker" in navigator) {
    window.addEventListener("load", function () {
      navigator.serviceWorker.register("/sw.js").then(
        function (registration) {
          //  console.log('Service Worker registrado com sucesso:', registration);
        },
        function (err) {
          console.log("Falha ao registrar o Service Worker:", err);
        }
      );
    });
  }

  return (
    <div>
      OFFLINE
      {/* <Main /> */}
    </div>
  );
}

export default App;
